.title {
  animation-duration: 1000ms;
  animation-delay: '0ms';
  animation-iteration-count: 1;
  opacity: 1;
}
.description {
  animation-duration: 1500ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  opacity: 1;
}
.portfolio {
  animation-duration: 1000ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  opacity: 1;
}
