.banner {
  animation-duration: 500ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  opacity: 1;
}

.presentation {
  animation-duration: 500ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  opacity: 1;
}

.presentation2 {
  animation-duration: 700ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  opacity: 1;
}

.contactMe {
  animation-duration: 900ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  opacity: 1;
}

.imageOne {
  animation-duration: 1400ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  opacity: 1;
}

.imageTwo {
  animation-duration: 1200ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  opacity: 1;
}

.imageThree {
  animation-duration: 1600ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  opacity: 1;
}

.imageFour {
  animation-duration: 1600ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  opacity: 1;
}

.imageFive {
  animation-duration: 2000ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  opacity: 1;
}
